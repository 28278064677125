.services {
  width: 100%;
  margin: auto;
  background-color: black;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.services_con {
  max-width: 1280px;
  margin: auto;
}

.services_title_con {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 10px;
}

.services_title {
  font-size: 40px;
  font-weight: 700;
  font-family: comic-bold;
  color: #fff;
}

.services_title_des {
  font-size: 20px;
  font-weight: 400;
  font-family: comic-regular;
  max-width: 550px;
  color: white;
}

.services_line {
  width: 100%;
  max-width: 600px;
  height: 3px;
  background: linear-gradient(to right, #fd0988, #7237bd);
}

.services_list_con {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.services_list_icon {
  width: 20px;
}

.services_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  background-color: #16163c;
  padding: 12px;
  border-radius: 20px;
  cursor: pointer;
}

.services_list_individual {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: #16163c;
  padding: 12px;
  border-radius: 20px;
  height: 100%;
}

.services_list:hover {
  background: linear-gradient(to right, #fd0988, #7237bd);
  transition: all 0.3s;

  .services_list_btn {
    background: linear-gradient(60.11deg, #16163c 18.25%, #7237bd 81.75%);
  }
}

.service_list_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.services_list_name {
  color: white;
  font-size: 24px;
  font-weight: 700;
  font-family: comic-bold;
}

.services_list_bulletpoints_con {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.services_list_des_con {
  display: flex;
  gap: 5px;
}

.services_list_point {
  min-width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: white;
  margin-top: 4px;
}

.services_list_des {
  font-size: 16px;
  font-weight: 400;
  font-family: comic-regular;
  color: white;
}

.services_list_order {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.services_list_price {
  font-size: 19px;
  font-weight: 700;
  font-family: comic-bold;
  color: #fff;
}

.services_list_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  //   width: 73px;
  //   height: 24px;
  border-radius: 10px;
  background: linear-gradient(to right, #fd0988, #7237bd);
  color: white;
  cursor: pointer;
  font-family: comic-regular;
  font-size: 18px;
  padding: 5px 10px;
}

.services_list_individual_name {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .services_title {
    font-size: 36px;
  }

  .services_title_des {
    font-size: 20px;
  }

  .services_list_con {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .services_list_name {
    font-size: 20px;
  }

  .services_list_des {
    font-size: 12px;
  }

  .services_list_btn {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .services_title {
    font-size: 24px;
  }

  .services_title_des {
    font-size: 16px;
    order: 2;
  }
}

@media screen and (max-width: 480px) {
  .services_list_name {
    font-size: 16px;
  }

  .services_list_des {
    font-size: 15px;
  }

  .services_list_btn {
    font-size: 14px;
  }

  .services_list_bulletpoints_con {
    display: none;
  }

  .services_list_individual {
    grid-column: 1 / 3;
  }
}

@media screen and (max-width: 360px) {
  .services_title {
    font-size: 16px;
  }

  .services_title_des {
    font-size: 12px;
  }

  .services_list {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .services_list_name {
    text-align: center;
  }

  .service_list_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .services_list_order {
    align-items: center;
  }

  .services_list_price {
    font-size: 15px;
  }

  .services_list_name {
    font-size: 12px;
  }

  .services_list_des {
    font-size: 10px;
  }
}
