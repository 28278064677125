.client {
  width: 100%;
  background-color: black;
  margin: auto;
  padding: 30px 20px 50px 20px;
}

.client_con {
  width: 100%;
  max-width: 1280px;
  margin: auto;
}

.client_title_con {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.client_title {
  font-size: 40px;
  font-weight: 700;
  font-family: comic-bold;
  color: #fff;
}

.client_line {
  width: 100%;
  max-width: 346px;
  height: 3px;
  background: linear-gradient(to right, #fd0988, #7237bd);
}

.client_slide {
  max-width: 160px;
  height: 160px;
  // background-color: white;
  border-radius: 10px;
}

.client_slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .client_title {
    font-size: 36px;
  }
}

@media screen and (max-width: 576px) {
  .client_title {
    font-size: 24px;
  }
}

@media screen and (max-width: 360px) {
  .client_title {
    font-size: 20px;
  }
}
