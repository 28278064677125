.header {
  // max-width: 1440px;
  margin: auto;
  // background-image: url("../../assets/img/background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // min-height: 100vh;
  padding: 0 20px;
  width: 100%;
  position: relative;
  -webkit-box-shadow: inset -1px -15px 16px 1px #000000;
  box-shadow: inset -1px -15px 16px 1px #000000;
  // z-index: 999;
}

.header_container {
  // max-width: 1280px;
  margin: auto;
}

.header_logo {
  // width: 120px;
  // object-fit: contain;
}

.header_navbar {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 122px 0;
}

.header_burger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  gap: 15px;
}

.header_burger_before {
  width: 50px;
  border: 2px solid white;
  border-radius: 10px;
}

.header_burger_main {
  width: 60px;
  border: 2px solid white;
  border-radius: 10px;
}

.header_burger_after {
  width: 50px;
  border: 2px solid white;
  border-radius: 10px;
}

.header_content {
  display: flex;
}

.header_content_social_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  z-index: 2;
  position: relative;
}

.header_social {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.header_social_scroll {
  font-size: 20px;
  transform: rotate(-90deg);
  text-transform: capitalize;
  font-family: comic-regular;
  font-weight: 400;
  color: white;
  margin-bottom: 20px;
}

.header_content_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: relative;
  z-index: 2;
}

.header_content_title {
  font-size: 96px;
  color: white;
  font-family: aleg-bold;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.header_content_des {
  font-size: 24px;
  font-weight: 300;
  font-family: comic-regular;
  color: white;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .header_logo {
    width: 50px;
  }

  .header_content_title {
    font-size: 48px;
  }

  .header_content_des {
    font-size: 20px;
  }

  .header_burger_before {
    width: 40px;
  }

  .header_burger_main {
    width: 50px;
  }

  .header_burger_after {
    width: 40px;
  }

  .header_logo {
    width: 80px;
  }
}

@media screen and (max-width: 360px) {
  .header_logo {
    width: 50px;
  }

  .header_content_title {
    font-size: 32px;
  }

  .header_content_des {
    font-size: 15px;
  }

  // .header_burger_before {
  //   width: 15px;
  // }

  // .header_burger_main {
  //   width: 20px;
  // }

  // .header_burger_after {
  //   width: 15px;
  // }
}
