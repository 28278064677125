* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 

body {
  background-color: black;
}

@font-face {
  font-family: comic-regular;
  src: url("./assets/fonts/Arimo-Regular.ttf");
}

@font-face {
  font-family: comic-semibold;
  src: url("./assets/fonts/Arimo-SemiBold.ttf");
}

@font-face {
  font-family: comic-bold;
  src: url("./assets/fonts/Arimo-Bold.ttf");
}

@font-face {
  font-family: aleg-regular;
  src: url("./assets/fonts/AlegreyaSans-Regular.ttf");
}

@font-face {
  font-family: aleg-bold;
  src: url("./assets/fonts/AlegreyaSans-Bold.ttf");
}

@font-face {
  font-family: aleg-extrabold;
  src: url("./assets/fonts/AlegreyaSans-ExtraBold.ttf");
}
