.notify {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 

.notify_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notify_title {
  color: white;
  font-size: 36px;
  font-family: comic-bold;
}

.notify_des {
  color: white;
  font-size: 24px;
  font-family: comic-bold;
}
