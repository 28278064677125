.offer {
  width: 100%;
  margin: auto;
  background-color: black;
  padding: 30px 20px;
}

.offer_con {
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-color: #191c26;
  padding: 50px 60px;
  border-radius: 20px;
}

.offer_title_con {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.offer_title {
  font-size: 40px;
  font-weight: 700;
  font-family: comic-bold;
  color: #fff;
}

.offer_des {
  font-size: 19px;
  font-weight: 400;
  font-family: comic-regular;
  color: white;
}

.offer_types_con {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.offer_types_name_con {
  display: flex;
  gap: 10px;
  align-items: center;
}

.offer_bullet {
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
}

.offer_types_name {
  font-size: 24px;
  font-weight: 400;
  font-family: comic-regular;
  color: #fff;
}

.offer_type_con {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.offer_choice {
  width: 100%;
  height: 38px;
  padding: 10px 33px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: comic-regular;
  cursor: pointer;
}

.offer_choice_clicked {
  width: 100%;
  height: 38px;
  padding: 10px 33px;
  color: white;
  font-size: 20px;
  font-weight: 400;
  background: linear-gradient(to right, #fd0988, #7237bd);
  // border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: comic-regular;
  cursor: pointer;
}

.offer_range {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  // align-self: center;
  // border: 1px solid red;
}

.offer_range_title_con {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.offer_range_name_con {
  display: flex;
  gap: 10px;
  align-items: center;
}

.offer_range_name {
  font-size: 24px;
  font-weight: 400;
  font-family: comic-regular;
  color: #fff;
}

.offer_range_des {
  font-size: 16px;
  color: white;
  font-weight: 400;
  font-family: comic-regular;
}

.offer_range_input_con {
  display: flex;
  flex-direction: column;
}

.offer_range_price_con {
  display: flex;
  align-items: center;
  padding-left: 50px;
  margin-bottom: 5px;
}

.offer_range_price {
  font-size: 24px;
  font-weight: 400;
  font-family: comic-regular;
  color: #fff;
}

.offer_range_input {
  width: 100%;
  max-width: 356px;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: linear-gradient(to right, #fd0988, #7237bd);
  border-radius: 50%;
  cursor: pointer;
}

.offer_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
}

.offer_input {
  width: 100%;
  max-width: 357px;
}

.offer_inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 10px;
  max-width: 734px;
  margin-bottom: 10px;
}

.offer_inputs_con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.offer_input {
  border: 3px solid #7237bd;
  //   border-image: linear-gradient(to right, #fd0988, #7237bd) 1;
  border-image-repeat: no-repeat;
  text-align: center;
  padding: 10px 20px;
  outline: none;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 400;
  font-family: comic-regular;
}

.offer_input::placeholder {
  font-size: 20px;
  font-weight: 400;
  font-family: comic-regular;
  text-align: center;
}

.offer_input_border {
  position: relative;
  display: flex;
  // background-color: white;
}

.highlight {
  color: red;
  position: absolute;
  right: 40%;
  top: 20%;
}

.offer_agg_con {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
}

.offer_agg_checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #191c26;
}

.offer_agg_checkbox:checked {
  background-image: url('../../../../assets/icons/checked.png');
}

.offer_checkbox_style {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 19px;
  height: 19px;
  background: linear-gradient(to right, #fd0988, #7237bd);
}

.offer_agreement {
  font-size: 16px;
  font-weight: 400;
  font-family: comic-regular;
  color: #fff;
}

.offer_btn {
  width: 100%;
  max-width: 215px;
  padding: 20px 0;
  color: white;
  background: linear-gradient(to right, #fd0988, #7237bd);
  font-weight: 400;
  font-size: 28px;
  font-family: comic-regular;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  margin: auto;
}

// @media screen and (max-width: 992px) {
// }

@media screen and (max-width: 768px) {
  .offer_title {
    font-size: 36px;
  }

  .offer_des {
    font-size: 15px;
  }

  .offer_choice {
    font-size: 16px;
  }

  .offer_choice_clicked {
    font-size: 16px;
  }

  .offer_range_price {
    font-size: 20px;
  }

  .offer_btn {
    font-size: 24px;
  }

  .offer_type_con {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .offer_types_name {
    font-size: 20px;
  }

  .offer_input {
    font-size: 16px;
  }

  .offer_input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .offer_con {
    gap: 20px;
  }

  .offer_title {
    font-size: 24px;
  }

  .offer_types_name {
    font-size: 16px;
  }

  .offer_type_con {
    display: grid;
    grid-template-columns: 1fr;
  }

  .offer_range_des {
    font-size: 12px;
    margin-top: 10px;
  }

  // .offer_input {
  //   font-size: 12px;
  // }

  // .offer_input::placeholder {
  //   font-size: 12px;
  // }

  .offer_con {
    padding: 20px;
  }

  .offer_agreement {
    font-size: 12px;
  }

  .offer_agg_con {
    margin-bottom: 20px;
  }

  .offer_btn {
    font-size: 14px;
    max-width: 150px;
  }
}

@media screen and (max-width: 480px) {
  .offer_inputs {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .offer_inputs {
    width: 100%;
  }
}

@media screen and (max-width: 382px) {
  .offer_inputs {
    grid-template-columns: 1fr;
  }

  .offer_title {
    font-size: 16px;
  }

  .offer_types_name {
    font-size: 15px;
  }

  .offer_choice {
    font-size: 14px;
  }

  .offer_choice_clicked {
    font-size: 14px;
  }

  .offer_des {
    font-size: 14px;
  }

  .offer_input {
    font-size: 12px;
  }

  .offer_input::placeholder {
    font-size: 12px;
  }

  .offer_range_price {
    font-size: 14px;
  }

  .offer_agreement {
    font-size: 10px;
  }

  .offer_btn {
    font-size: 12px;
  }
}
