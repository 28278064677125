.team {
  width: 100%;
  margin: auto;
  background-color: #000;
  padding: 20px 20px;
  padding-right: 0;
}

.team_con {
  width: 100%;
  max-width: 1280px;
  margin: auto;
}

.team_title_con {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 100px;
  margin-bottom: 30px;
}

.team_title {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  font-family: comic-bold;
}

.team_line {
  width: 100%;
  max-width: 346px;
  height: 3px;
  background: linear-gradient(to right, #fd0988, #7237bd);
}

.team_title_des {
  font-size: 20px;
  font-weight: 400;
  font-family: comic-regular;
  color: white;
  max-width: 600px;
  line-height: 20px;
}

// .team_people {
//     display: flex;
//     flex-direction: column;
// }

.team_person {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.team_person_img {
  width: 165px;
  height: 143px;
  border: 5px solid #ffffff;
  background-color: white;
  border-radius: 139px 38px 200px 174px;
  -moz-border-radius: 139px 38px 200px 174px;
  -webkit-border-radius: 139px 38px 200px 174px;
  border: 5px solid #fff;
  overflow: hidden;
}

.team_person_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team_person_name {
  font-size: 19px;
  font-weight: 700;
  font-family: comic-bold;
  color: white;
  max-width: 250px;
  text-align: center;
}

.team_person_des {
  font-size: 16px;
  font-family: comic-regular;
  font-weight: 400;
  color: white;
}

@media screen and (max-width: 768px) {
  .team_title_con {
    padding-left: 0;
  }

  .team_title {
    font-size: 36px;
  }

  .team_title_des {
    font-size: 20px;
  }
}

@media screen and (max-width: 576px) {
  .team_person_img {
    max-width: 130px;
    height: 120px;
  }

  .team_person_name {
    font-size: 15px;
  }

  .team_person_des {
    font-size: 12px;
  }

  .team_title {
    font-size: 24px;
  }

  .team_title_des {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .team_person_img {
    max-width: 80px;
    height: 80px;
  }

  // .team_title {
  //   font-size: 16px;
  // }
}

@media screen and (max-width: 360px) {
  .team_title_des {
    font-size: 12px;
  }

  .team_person_name {
    font-size: 12px;
  }

  .team_person_des {
    font-size: 10px;
  }
}
